.agendaHeader {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    text-align: center;
    margin-top: 20px;
}

.panel {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid var(--panel-border);
    padding: 15px;
    margin-bottom: 20px;
}

.panelNoHeader {
    margin-top: 20px;
}

.tableDropdownButton {
    padding: 0px !important;
    margin-right: 15px;
}

.tableDropdownButton:active {
    border-color: transparent !important;
}

.tableDropdown button {
    border-color: transparent !important;
}

.tableDropdownItem {
    cursor: pointer;
}