.basicMultiSelect {
    border-bottom: solid 4px var(--tab-border);
}

.basicMultiSelect>div {
    border-color: var(--natural);
    border-radius: 6px 6px 0px 0px;
}

.basicMultiSelect>div:focus {
    border-color: var(--natural);
    border-radius: 6px 6px 0px 0px;
}

.basicMultiSelect>div>div>div {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    /* color: var(--panel-header); */
}