.customInputLabel {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--seondary-text);
}

.customInputBasic {
    font-family: 'Roboto';
    font-weight: 400;
    color: var(--primary-text);
    border: 1px solid var(--natural);
    margin-bottom: 15px;
}

@media (max-width: 1900px) {
    .customInputBasic {
        margin-bottom: 10px;
    }
}

.customInputBasic:focus {
    box-shadow: none !important;
}

.customInputBasicWithButtonContainer {
    margin-bottom: 15px;
}

@media (max-width: 1900px) {
    .customInputBasicWithButtonContainer {
        margin-bottom: 10px;
    }
}

.customInputBasicWithButton {
    font-family: 'Roboto';
    font-weight: 400;
    color: var(--primary-text);
    border: 1px solid var(--natural);
    max-width: 300px;
    border-radius: 0.375rem 0px 0px 0.375rem;
}

.customInputBasicWithButton:focus {
    box-shadow: none !important;
}

.customBlockInputBasicWithButton {
    font-family: 'Roboto';
    font-weight: 400;
    color: var(--primary-text);
    border: 1px solid var(--natural);
    border-radius: 0.375rem 0px 0px 0.375rem;
}

.customBlockInputBasicWithButtonMargin {
    margin-bottom: 20px;
}

.customInputButton {
    font-weight: 500;
    color: var(--primary);
    background-color: #FFF;
    border-color: var(--primary);
    border-radius: 0px 0.375rem 0.375rem 0px;
}

.customInputButton:hover {
    font-weight: 500;
    color: var(--primary-dark);
    background-color: #FFF;
    border-color: var(--primary-dark);
}

.customBlockInputButton {
    font-weight: 500;
    color: var(--primary);
    background-color: #FFF;
    border-color: var(--primary);
    border-radius: 0px 0.375rem 0.375rem 0px;
    white-space: nowrap;
}

.customBlockInputButton:hover {
    font-weight: 500;
    color: var(--primary-dark);
    background-color: #FFF;
    border-color: var(--primary-dark);
}

.customErrorInputBasic {
    border-color: var(--required) !important;
}

.requiredIndicator {
    font-family: 'Roboto';
    font-weight: 400;
    color: var(--required);
}

.helperErrorText {
    display: block;
    margin-top: .25rem;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: .825em;
    color: var(--required);
    margin-top: -15px;
    margin-bottom: 20px;
}

.helperErrorTextNoMargin {
    display: block;
    margin-top: .25rem;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: .825em;
    color: var(--required);
    margin-bottom: 20px;
}

.helperErrorTextReactSelect {
    display: block;
    margin-top: .25rem;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: .825em;
    color: var(--required);
    margin-top: 5px;
    margin-bottom: 20px;
}

.helperMessageText {
    display: block;
    margin-top: .25rem;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: .825em;
    color: var(--success-dark);
}

.FeedbackIconContainer {
    position: relative;
}

.FeedbackIcon {
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}

.customCheckboxText {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-text);
}

.customCheckbox {
    box-shadow: none;
}

.customCheckbox:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}

.customCheckbox:disabled {
    background-color: var(--panel-header);
    border-color: var(--panel-header);
}

.customCheckboxVerticalContainer {
    height: 38px;
    display: flex;
    align-items: center;
}

.customRadiobuttonText {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-text);
}

.customRadiobutton {
    box-shadow: none;
    margin-bottom: 15px;
}

@media (max-width: 1900px) {
    .customRadiobutton {
        margin-bottom: 10px;
    }
}

.customRadiobutton:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}

.customPreviewLabel {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--seondary-text);
}

.customMultiPreviewLabel {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--seondary-text);
    display: block;
    border-bottom: solid 1px var(--seondary-text);
}

.customMultiPreviewLabelNoBorder {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--seondary-text);
}

.customMultiFilePreviewLabel {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--seondary-text);
}

.customPreview {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--primary-text);
    margin-bottom: 15px;
}

.customPreviewNoMargin {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--primary-text);
}

.customMultiPreviewContainer {
    margin-bottom: 15px;
}

.customMultiPreview {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--primary-text);
    margin-top: 10px;
    padding-bottom: 10px;
    display: block;
    border-bottom: solid 1px var(--panel-border);
}

.customMultiPreviewNoBorder {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--primary-text);
    margin-top: 5px;
    padding-bottom: 5px;
}

.customMultiFilePreview {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--primary);
    margin-top: 5px;
    padding-bottom: 5px;
    text-decoration: none;
}

.customImgPreviewContainer {
    width: fit-content;
    position: relative;
    cursor: pointer;
}

.customImgPreviewIcon {
    color: var(--primary);
    font-size: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.customColorPicker {
    font-family: 'Roboto';
    font-weight: 400;
    color: var(--primary-text);
    border: 1px solid var(--natural);
    margin-bottom: 15px;
    background-color: #FFF !important;
}

@media (max-width: 1900px) {
    .customColorPicker {
        margin-bottom: 10px;
    }
}

.customColorPicker:focus {
    box-shadow: none !important;
}

.principalPreviewContainer {
    display: flex;
    justify-content: center;
}

.principalPreviewIcon {
    width: 24px;
    height: 24px;
}

.principalPreviewIconActive {
    background-image: url('/public/img/principal_icon.svg');
}

.principalPreviewIconInActive {
    background-image: url('/public/img/principal_icon_inactive.svg');
}

.customPrincipalText {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--seondary-text);
}

.activoPreviewContainer {
    display: flex;
    justify-content: center;
}

.activoPreviewIcon {
    font-size: 22px;
    margin-top: 1px;
    margin-bottom: 1px;
}

.activoPreviewIconActive {
    color: var(--primary);
}

.activoPreviewIconInActive {
    color: var(--natural);
}

.customActivoText {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--seondary-text);
}

.circledImg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 160px;
    height: 160px;
    border-radius: 100%;
}

@media (max-width: 1199px) {
    .circledImg {
        width: 140px;
        height: 140px;
    }
}

.circledImgError {
    border: solid 1px var(--required);
}

.circledImgHelperErrorText {
    display: block;
    margin-top: .25rem;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: .825em;
    color: var(--required);
    margin-bottom: 20px;
}

.basicMultiSelect {
    margin-bottom: 15px;
}

@media (max-width: 1900px) {
    .basicMultiSelect {
        margin-bottom: 10px;
    }
}

.basicMultiSelect>div {
    border-color: var(--natural);
}

.basicMultiSelectPreview>div {
    background-color: transparent;
    border: none;
    width: -webkit-fill-available;
}

.basicMultiSelectPreview .select__indicators {
    display: none;
}

.switch:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}

.diaSelector {
    background-color: white;
    border: solid 1px var(--primary-light);
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: black;
    cursor: pointer;
}

.diaSelectorActive {
    background-color: var(--primary);
    border: solid 1px black;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    cursor: pointer;
}